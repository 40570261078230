// The different configuration values available for the key 'styleType'.
const STYLE_TYPE = {
  BRANDED: 'branded',
};

// The different values available for the 'type' of the message sent/received.
const MESSAGE_TYPE = {
  FRAME_READY: 'CCC:FRAME_READY',
  FRAME_SCROLL: 'CCC:FRAME_SCROLL',
  FRAME_HEIGHT: 'CCC:FRAME_HEIGHT',
  FRAME_CONTEXT: 'CCC:FRAME_CONTEXT',

  FORM_ERROR: 'CCC:FORM_ERROR',
  FORM_SUBMIT: 'CCC:FORM_SUBMIT',
  FORM_GOBACK: 'CCC:FORM_GOBACK',
  FORM_DISABLED: 'CCC:FORM_DISABLED',

  TOKEN_ERROR: 'CCC:TOKEN_ERROR',
  TOKEN_SUCCESS: 'CCC:TOKEN_SUCCESS',
  TOKEN_LOADING: 'CCC:TOKEN_LOADING',

  FIELD_AMT_CHANGED: 'CCC:FIELD_AMT_CHANGED',
  FIELD_DATE_CHANGED: 'CCC:FIELD_DATE_CHANGED',
};

// This value is used for the 'origin' attribute of the message, can be changed based on requirement.
const TARGET_ORIGIN = '*';

// The different configuration values available for the key 'techErrorType'.
const TECH_ERROR_TYPE = {
  TIMEOUT_ERR: 'Secure CCC TimeOut Error',
  TOKEN_ERR: 'Secure CCC Tokenization Error',
  APP_NOT_RESPONDING_ERR: 'Digital Payment Xapi is not responding.',
};

export { STYLE_TYPE, MESSAGE_TYPE, TARGET_ORIGIN, TECH_ERROR_TYPE };
