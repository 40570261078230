import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageTemplate from '../../templates/PageTemplate';
import PRNPolicyDetailsPage from '../PRNPolicyDetailsPage';
import PageContent from '../../organisms/PageContent';
import CardDetailsPage from '../CardDetailsPage';
import ConfirmPaymentDetailsPage from '../ConfirmPaymentDetailsPage';
import ConfirmationPage from '../ConfirmationPage';
import TechErrorPage from '../TechErrorPage';
import {
  BRAND_CONFIG,
  PAGE_INDEX,
} from '../../../utils/constants/commonConstants';
import { BrandContext } from '../../organisms/BrandContext';
import LoadingSpinner from '../../molecules/LoadingSpinner';
import SessionTimeoutPage from '../SessionTimeoutPage';

function App({ config }) {
  const [pageIndex, isTechError, isSessionTimeout] = useSelector((state) => [
    state.navigationProgress.currentPage,
    state.navigationProgress.isTechError,
    state.navigationProgress.isSessionTimeout,
  ]);
  const { isLoading, loaderComponent } = useSelector(
    (state) => state.helpers.loadingHelper,
  );

  const [isPaymentError, setIsPaymentError] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [
    paymentBusinessErrorMessage,
    setPaymentBusinessErrorMessage,
  ] = useState('');

  const renderContentPage = (pageIndex) => {
    switch (pageIndex) {
      case PAGE_INDEX.PRN_POLICY_DETAILS_PAGE:
        return <PRNPolicyDetailsPage />;
      case PAGE_INDEX.CARD_DETAILS_PAGE:
        return (
          <CardDetailsPage
            isPaymentError={isPaymentError}
            setIsPaymentError={setIsPaymentError}
            paymentErrorMessage={paymentErrorMessage}
            setPaymentErrorMessage={setPaymentErrorMessage}
            paymentBusinessErrorMessage={paymentBusinessErrorMessage}
          />
        );
      case PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE:
        return (
          <ConfirmPaymentDetailsPage
            setIsPaymentError={setIsPaymentError}
            setPaymentErrorMessage={setPaymentErrorMessage}
            setPaymentBusinessErrorMessage={setPaymentBusinessErrorMessage}
          />
        );
      case PAGE_INDEX.CONFIRMATION_PAGE:
        return <ConfirmationPage />;
    }
  };

  const renderErrorPage = (isSessionTimeout) => {
    if (isSessionTimeout) {
      return <SessionTimeoutPage />;
    } else {
      return <TechErrorPage />;
    }
  };
  return (
    <BrandContext.Provider value={BRAND_CONFIG}>
      <PageTemplate appEnv={config.appEnv}>
        <PageContent
          gridColLargeSpan={isTechError || isSessionTimeout ? 10 : 6}
          pageIndex={isTechError || isSessionTimeout ? 0 : pageIndex}
          setIsPaymentError={setIsPaymentError}
          setPaymentErrorMessage={setPaymentErrorMessage}
          setPaymentBusinessErrorMessage={setPaymentBusinessErrorMessage}
        >
          {isLoading && loaderComponent && <LoadingSpinner />}
          {isTechError || isSessionTimeout
            ? renderErrorPage(isSessionTimeout)
            : renderContentPage(pageIndex)}
        </PageContent>
      </PageTemplate>
    </BrandContext.Provider>
  );
}

App.propTypes = {
  config: PropTypes.object.isRequired,
};

export default App;
