import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Heading,
  Grid,
  Divider,
} from '@suncorp/styleguide-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import PolicyDetails from '../../molecules/PolicyDetails';
import CardAndPaymentDetails from '../../molecules/CardAndPaymentDetails';
import { BrandContext } from '../../organisms/BrandContext';
import {
  getBusinessErrors,
  PAYMENT_ERROR_TITLE,
} from '../../../utils/constants/businessErrorConstants';
import {
  updateAnalyticsOnBusinessOrTechnicalError,
  updateAnalyticsOnPageRender,
} from '../../../utils/analytics/dataLayerUtils';
import { isPolicyNumber } from '../../../utils/validators';
import { TECH_ERROR_TYPE } from '../../organisms/CardCaptureComponent/utils/constants';
import { isActiveCustomer } from '../../../middlewares/sessionTimeoutMiddleware';

const ConfirmPaymentDetailsPage = ({
  setIsPaymentError,
  setPaymentErrorMessage,
  setPaymentBusinessErrorMessage,
}) => {
  const dispatch = useDispatch();
  const [disablePayNowButton, setDisablePayNowButton] = useState(false);
  const [
    prnNumber,
    policyNumber,
    email,
    typeOfPrn,
    cardDetails,
    paymentAmount,
    inRenewal,
    productDetails,
    sessionTimeout,
  ] = useSelector((state) => [
    state.policyDetails.prnNumber,
    state.policyDetails.policyNumber,
    state.policyDetails.email,
    state.policyDetails.typeOfPrn,
    state.cardAndPaymentDetails.cardDetails,
    state.cardAndPaymentDetails.paymentAmount,
    state.policyDetails.inRenewal,
    state.policyDetails.productDetails,
    state.sessionTimeout,
  ]);
  const cardExpiry = `${cardDetails.expiryMonth}/${cardDetails.expiryYear}`;

  const brandConfig = useContext(BrandContext);
  const [paymentTechErrorMessage, setPaymentTechErrorMessage] = useState('');
  const makePolicyPayment = async () => {
    try {
      return await dispatch.transactionDetails.makeCallToPolicyPaymentAPI(
        brandConfig.apiBrandName,
      );
    } catch (error) {
      return error.response;
    }
  };

  const hideLoadingSpinner = () => {
    dispatch.helpers.setLoading({
      isLoading: false,
      loaderComponent: '',
    });
  };

  const handleSubmit = () => {
    setDisablePayNowButton(true);
    const currentActiveTime = Date.now();
    const { lastActiveTime } = sessionTimeout;
    if (!isActiveCustomer(lastActiveTime, currentActiveTime)) {
      dispatch.navigationProgress.setIsSessionTimeout(true);
      return;
    }

    const callPolicyPaymentApiWithSpinner = async () =>
      dispatch.helpers.callWithLoader({
        asyncCallbackFunction: makePolicyPayment,
        loaderComponent: 'defaultLoadingIndicator',
      });
    callPolicyPaymentApiWithSpinner().then((response) => {
      // When the response returns errorDetails
      if (
        response &&
        response.paymentResponse &&
        response.paymentResponse.errorDetails &&
        Object.entries(response.paymentResponse.errorDetails).length !== 0
      ) {
        // When the response returns the code is INVALID_CARD_DETAILS
        if (
          response.paymentResponse.errorDetails.code ===
          PAYMENT_ERROR_TITLE.INVALID_CARD_DETAILS
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber)
              .INVALID_EXPIRED_PAYMENT_PROCESS_CARD,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response returns the code is DECLINED_CARD
        else if (
          response.paymentResponse.errorDetails.code ===
          PAYMENT_ERROR_TITLE.DECLINED_CARD
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber)
              .DECLINED_INSUFFICIENT_AMOUNT_CARD,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response returns the code is EXPIRED_CARD
        else if (
          response.paymentResponse.errorDetails.code ===
          PAYMENT_ERROR_TITLE.EXPIRED_CARD
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber)
              .INVALID_EXPIRED_PAYMENT_PROCESS_CARD,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response returns the code is INSUFFICIENT_AMOUNT_CARD
        else if (
          response.paymentResponse.errorDetails.code ===
          PAYMENT_ERROR_TITLE.INSUFFICIENT_AMOUNT_CARD
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber)
              .DECLINED_INSUFFICIENT_AMOUNT_CARD,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response returns the code is PAYMENT_PROCESS_EXCEPTION
        else if (
          isPolicyNumber(prnNumber, policyNumber, typeOfPrn) &&
          response.paymentResponse.errorDetails.code ===
            PAYMENT_ERROR_TITLE.PAYMENT_PROCESS_EXCEPTION
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber)
              .INVALID_EXPIRED_PAYMENT_PROCESS_CARD,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response returns the code is PAYMENT_GATEWAY_ERROR
        else if (
          !isPolicyNumber(prnNumber, policyNumber, typeOfPrn) &&
          response.paymentResponse.errorDetails.code ===
            PAYMENT_ERROR_TITLE.PAYMENT_GATEWAY_ERROR
        ) {
          setIsPaymentError(true);
          setPaymentErrorMessage(
            getBusinessErrors(brandConfig, prnNumber).PAYMENT_GATEWAY_ERROR,
          );
          setPaymentBusinessErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          // Clear the cardDetails state.
          dispatch.cardAndPaymentDetails.setCardDetails({});
          dispatch.navigationProgress.setPageIndex(
            PAGE_INDEX.CARD_DETAILS_PAGE,
          );
        } // When the response comes with other than the above codes we will display below page
        else {
          // Show tech error page, then hide the loading spinner
          dispatch.navigationProgress.setIsTechError(true);
          setPaymentTechErrorMessage(
            response.paymentResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        }
      } // When the response is successful
      else if (response && response.paymentResponse) {
        // Set the transaction details
        dispatch.transactionDetails.setReceiptNumber(
          response.paymentResponse.receiptNo,
        );
        dispatch.transactionDetails.setTransactionDate(
          response.paymentResponse.transactionDate,
        );
        dispatch.policyDetails.setEmail(response.paymentResponse.emailAddress);
        dispatch.policyDetails.setPRNNumber(
          response.paymentResponse.paymentReferenceNumber,
        );
        // Go to the next page, then hide the loading spinner
        dispatch.navigationProgress.setPageIndex(PAGE_INDEX.CONFIRMATION_PAGE);
        hideLoadingSpinner();
      } // When there are any other errors not caught above
      else {
        // Show tech error page, then hide the loading spinner
        setPaymentTechErrorMessage(
          response && response.data && response.data.errors
            ? response.data.errors[0].title
            : TECH_ERROR_TYPE.APP_NOT_RESPONDING_ERR,
        );
        dispatch.navigationProgress.setIsTechError(true);
        hideLoadingSpinner();
      }
    });
  };

  // Update dataLayer and publish pageview
  useEffect(
    () => {
      // Update analytics only once DOM has fully loaded
      const onPageLoad = () => {
        updateAnalyticsOnPageRender(
          PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE,
          prnNumber,
          policyNumber,
          paymentAmount,
          cardDetails.cardType,
          inRenewal,
          productDetails,
        );
      };

      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Update dataLayer when error occurs and publish pageview
  useEffect(() => {
    // Update analytics when error updates
    if (paymentTechErrorMessage) {
      updateAnalyticsOnBusinessOrTechnicalError(
        PAGE_INDEX.CARD_DETAILS_PAGE,
        paymentTechErrorMessage,
        productDetails,
        false,
        true,
      );
    }
  }, [paymentTechErrorMessage, productDetails]);

  return (
    <section id="confirm-payment-details-section">
      <Heading rank={1} className="sg-u-paddingBottom--x2 sg-Type--heading2">
        Confirm your payment
      </Heading>
      <Grid>
        <PolicyDetails
          prnNumber={prnNumber}
          policyNumber={policyNumber}
          email={email}
          typeOfPrn={typeOfPrn}
        ></PolicyDetails>
        <Divider></Divider>
        <CardAndPaymentDetails
          cardName={cardDetails.nameOnCard}
          cardNumber={cardDetails.cardNumber}
          cardExpiry={cardExpiry}
          cardType={cardDetails.cardType}
          paymentAmount={paymentAmount}
        ></CardAndPaymentDetails>
      </Grid>
      <div className="sg-Form-pager sg-Form-pager-collapseMedium">
        <Button
          appearance="primary"
          className="sg-Btn--callToAction"
          onClick={handleSubmit}
          disabled={disablePayNowButton}
        >
          Pay Now
        </Button>
        <Button
          appearance="tertiary"
          onClick={() => {
            // Show the loading spinner.
            dispatch.helpers.setLoading({
              isLoading: true,
              loaderComponent: 'defaultLoadingIndicator',
            });
            // Go to the previous page.
            dispatch.navigationProgress.setPageIndex(
              PAGE_INDEX.CARD_DETAILS_PAGE,
            );
            // Clear the cardDetails state.
            dispatch.cardAndPaymentDetails.setCardDetails({});
          }}
        >
          Back
        </Button>
      </div>
    </section>
  );
};

ConfirmPaymentDetailsPage.propTypes = {
  setIsPaymentError: PropTypes.func.isRequired,
  setPaymentErrorMessage: PropTypes.func.isRequired,
  setPaymentBusinessErrorMessage: PropTypes.func.isRequired,
};

export default ConfirmPaymentDetailsPage;
