import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  GridCol,
  GridRow,
  Heading,
  Icon,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { currencyFormatter } from '../../../utils/formatters';
import { isPolicyNumber } from '../../../utils/validators';
import PaymentReceiptGridRow from '../../molecules/PaymentReceiptGridRow';
import { updateAnalyticsOnPageRender } from '../../../utils/analytics/dataLayerUtils';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';

const ConfirmationPage = () => {
  const [
    prnNumber,
    policyNumber,
    email,
    typeOfPrn,
    paymentAmount,
    cardType,
    inRenewal,
    productDetails,
    receiptNumber,
    transactionDate,
  ] = useSelector((state) => [
    state.policyDetails.prnNumber,
    state.policyDetails.policyNumber,
    state.policyDetails.email,
    state.policyDetails.typeOfPrn,
    state.cardAndPaymentDetails.paymentAmount,
    state.cardAndPaymentDetails.cardDetails.cardType,
    state.policyDetails.inRenewal,
    state.policyDetails.productDetails,
    state.transactionDetails.receiptNumber,
    state.transactionDetails.transactionDate,
  ]);

  // Update dataLayer and publish pageview
  useEffect(
    () => {
      // Update analytics only once DOM has fully loaded
      const onPageLoad = () => {
        updateAnalyticsOnPageRender(
          PAGE_INDEX.CONFIRMATION_PAGE,
          prnNumber,
          policyNumber,
          paymentAmount,
          cardType,
          inRenewal,
          productDetails,
          transactionDate,
          receiptNumber,
        );
      };

      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <section id="confirmation-section" className="sg-u-width10of10">
      <div className="sg-u-textCenter">
        <Icon
          name="tick"
          color="light"
          size="xxxlarge"
          circle
          className="sg-u-imageReplacement sg-u-bgCorrect"
        ></Icon>
      </div>
      <Heading rank={1} className="sg-u-textCenter">
        Thank you for your payment
      </Heading>
      <Grid>
        <GridRow className="sg-u-textCenter">
          <GridCol>
            <Heading
              rank={2}
              className="sg-u-marginBottom--x2 sg-Type--heading6"
            >
              Your payment was successful
            </Heading>
            <Paragraph className="sg-u-noMargin">
              We have emailed these details to
            </Paragraph>
            <Paragraph
              id="email"
              className="sg-u-noMarginTop sg-Type--bold sg-u-marginBottom"
            >
              {email}
            </Paragraph>
            <Paragraph>
              Please keep a record of these receipt details for your reference.
            </Paragraph>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol>
            <hr />
          </GridCol>
        </GridRow>
        <GridRow spacing="around">
          <GridCol>
            <Heading rank={3} className="sg-Type--heading6 sg-u-marginBottom">
              Payment receipt
            </Heading>
          </GridCol>
        </GridRow>
        <PaymentReceiptGridRow
          id="date"
          sectionName="Date"
          value={transactionDate}
        ></PaymentReceiptGridRow>
        <PaymentReceiptGridRow
          id="prn-number"
          sectionName={
            isPolicyNumber(prnNumber, policyNumber, typeOfPrn)
              ? 'Policy number'
              : 'Payment Reference Number (PRN)'
          }
          value={prnNumber}
        ></PaymentReceiptGridRow>
        <PaymentReceiptGridRow
          id="receipt-number"
          sectionName="Receipt number"
          value={receiptNumber}
        ></PaymentReceiptGridRow>
        <PaymentReceiptGridRow
          id="amount"
          sectionName="Amount"
          value={currencyFormatter(paymentAmount, 2)}
        ></PaymentReceiptGridRow>
      </Grid>
    </section>
  );
};

export default ConfirmationPage;
