import React from 'react';
import {
  Paragraph,
  GridCol,
  GridRow,
  Link,
  Heading,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';
import CardTypeImage from '../../atoms/CardTypeImage';
import CommonGridRow from '../CommonGridRow';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import { useDispatch } from 'react-redux';
import { currencyFormatter } from '../../../utils/formatters';

const CardAndPaymentDetails = ({
  cardName,
  cardNumber,
  cardExpiry,
  cardType,
  paymentAmount,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <GridRow
        spacing="around"
        className="sg-u-paddingTop--x2 sg-u-paddingBottom"
      >
        <GridCol
          span={{
            default: 8,
            large: 8,
            small: 7,
            xlarge: 8,
          }}
        >
          <Heading rank={3} className="sg-Type--heading6">
            Payment card details
          </Heading>
        </GridCol>
        <GridCol
          className="sg-u-flex--top sg-u-flex--right"
          span={{
            default: 4,
            large: 4,
            small: 5,
            xlarge: 4,
          }}
        >
          <Link
            className="sg-Type--colourSecondary sg-Type--iconText Icon-edit--secondary"
            data-testid="card-details-edit"
            onClick={() => {
              // Show the loading spinner.
              dispatch.helpers.setLoading({
                isLoading: true,
                loaderComponent: 'defaultLoadingIndicator',
              });
              // Go to the previous page.
              dispatch.navigationProgress.setPageIndex(
                PAGE_INDEX.CARD_DETAILS_PAGE,
              );
              // Clear the cardDetails state.
              dispatch.cardAndPaymentDetails.setCardDetails({});
            }}
          >
            Edit
          </Link>
        </GridCol>
      </GridRow>
      <CommonGridRow sectionName="Card name" value={cardName}></CommonGridRow>
      <GridRow spacing="around" className="sg-u-marginBottom">
        <GridCol
          span={{
            default: 12,
            large: 8,
            small: 12,
            xlarge: 8,
          }}
          contentAlign="middleLeft"
        >
          <Paragraph className="sg-Type--small sg-u-noMarginBottom">
            Card number
          </Paragraph>
        </GridCol>
        <GridCol
          span={{
            default: 12,
            large: 4,
            small: 12,
            xlarge: 4,
          }}
          contentAlign="middleRight"
        >
          <Paragraph className="sg-Type--bold sg-u-mediumAndSmallHidden sg-u-marginRight--x0point5 sg-u-flex--middle sg-u-noMarginBottom">
            {cardNumber}
          </Paragraph>
          <CardTypeImage
            type={cardType}
            className="sg-u-mediumAndSmallHidden"
          ></CardTypeImage>
          <div className="sg-u-width10of10 sg-u-largeHidden sg-u-flex--middle sg-u-marginBottom">
            <Paragraph className="sg-Type--bold sg-u-marginRight--x0point5 sg-u-floatLeft sg-u-flex--middle sg-u-noMarginBottom">
              {cardNumber}
            </Paragraph>
            <CardTypeImage type={cardType}></CardTypeImage>
          </div>
        </GridCol>
      </GridRow>
      <GridRow spacing="around" className="sg-u-paddingBottom--x4">
        <GridCol
          span={{
            default: 12,
            large: 8,
            small: 12,
            xlarge: 8,
          }}
          contentAlign="middleLeft"
        >
          <Paragraph className="sg-Type--small sg-u-noMarginBottom">
            Card expiry
          </Paragraph>
        </GridCol>
        <GridCol
          span={{
            default: 12,
            large: 4,
            small: 12,
            xlarge: 4,
          }}
          contentAlign="middleRight"
        >
          <Paragraph className="sg-Type--bold sg-u-mediumAndSmallHidden sg-u-noMarginBottom">
            {cardExpiry}
          </Paragraph>
          <Paragraph className="sg-Type--bold sg-u-floatLeft sg-u-width10of10 sg-u-largeHidden">
            {cardExpiry}
          </Paragraph>
        </GridCol>
      </GridRow>
      <GridRow spacing="around">
        <GridCol className="sg-u-paddingBottom">
          <Heading rank={3} className="sg-Type--heading6">
            Total payment amount
          </Heading>
        </GridCol>
        <GridCol
          span={{
            default: 12,
            large: 12,
            small: 12,
            xlarge: 12,
          }}
        >
          <Heading rank={2} className="sg-Type--heading2">
            {currencyFormatter(paymentAmount, 2)}
          </Heading>
        </GridCol>
        <GridCol>
          <Paragraph size={14}>
            If the payment is successful, the amount will be debited from your
            payment card.
          </Paragraph>
        </GridCol>
      </GridRow>
    </>
  );
};

CardAndPaymentDetails.propTypes = {
  cardName: PropType.string,
  cardNumber: PropType.string,
  cardExpiry: PropType.string,
  cardType: PropType.string,
  paymentAmount: PropType.string,
};

export default CardAndPaymentDetails;
