import React, { useContext } from 'react';
import {
  Heading,
  Form,
  SubmitButton,
  withValidation,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';
import PRNPolicyDetailsInput from '../../molecules/PRNPolicyDetailsInput';
import EmailInput from '../../molecules/EmailInput';
import BusinessErrorCard from '../../molecules/BusinessErrorCard';
import { BrandContext } from '../../organisms/BrandContext';

const PRNPolicyDetailsForm = ({
  bindFieldToValidation,
  validateFields,
  values,
  submitForm,
  isBusinessError,
  errorMessage,
}) => {
  const brandConfig = useContext(BrandContext);
  return (
    <>
      {isBusinessError && (
        <BusinessErrorCard
          errorTitle={'Please check your details'}
          errorMessage={errorMessage}
          brandConfig={brandConfig}
        />
      )}
      <Heading rank={2} className="sg-Type--size22 sg-u-paddingBottom">
        Your Details
      </Heading>
      <Form
        id="prn-policy-details-form"
        submitButton={
          <SubmitButton
            id="prn-policy-details-submit-button"
            data-testid="prn-policy-details-submit-button"
            appearance="primary"
            className="sg-u-width10of10"
            onClick={(e) => {
              e.preventDefault();
              validateFields(['emailAddress', 'prnPolicyNumber'], (errors) => {
                if (errors && Object.keys(errors).length === 0) {
                  submitForm();
                }
              });
            }}
          >
            Next
          </SubmitButton>
        }
      >
        <PRNPolicyDetailsInput
          bindFieldToValidation={bindFieldToValidation}
          defaultValue={values.prnPolicyNumber}
        />
        <EmailInput
          bindFieldToValidation={bindFieldToValidation}
          defaultValue={values.emailAddress}
        />
      </Form>
    </>
  );
};

PRNPolicyDetailsForm.propTypes = {
  bindFieldToValidation: PropType.func,
  validateFields: PropType.func,
  values: PropType.object,
  submitForm: PropType.func,
  isBusinessError: PropType.bool,
  errorMessage: PropType.string,
};

export default withValidation(PRNPolicyDetailsForm);
