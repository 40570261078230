import createApiInstance from '../utils/apis/createApiInstance';
import { makePolicyPaymentAPIRequest } from './mappers/policyPaymentApiMappers';

export const callPolicyPaymentApi = async (brand, state) => {
  const policyPaymentUrl = `${window.envConfig.digitalPaymentsApiBaseUrl}/policyPayment`;
  const requestBody = makePolicyPaymentAPIRequest(brand, state);
  const response = await createApiInstance({
    timeout: 20000,
  }).post(policyPaymentUrl, requestBody);
  return response.data?.data?.attributes;
};
